<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok') }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="编号" >
              <a-input v-decorator="['code', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="名称">
              <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="仓库联系人">
              <a-input v-decorator="['sender_name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="联系人电话">
              <a-input v-decorator="['send_mobile', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
            </a-form-item>
            <a-form-item label="位置" >
              <a-input v-decorator="['location', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" allow-clear/>
              <a-select
                v-if="false"
                v-decorator="[
                  'location',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.CorporationLocation">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="商品类型" >
              <a-select
                v-decorator="[
                  'goods_type',
                  {
                    rules: [
                      { required: true, message: '请选择' },
                    ],
                  },
                ]"
                placeholder="选择"
              >
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.GoodsType">{{ val }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="面积(平方米)" v-if="false">
              <a-input v-decorator="['area', {rules: [{required: true, message: '必填项，请填写信息'}]}]" allow-clear/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'name', 'code', 'sender_name', 'send_mobile', 'location', 'goods_type']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      formLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 7 }
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 13 }
        }
      }
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  }
}
</script>
